import React from 'react';

const callbackMap = new WeakMap<Element, (isVisible: boolean) => void>();
const observersMap = new WeakMap<Element, IntersectionObserver>();

function initIntersectionObserver(parentRef?: React.RefObject<Element>) {
    const intersectionObserver = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                callbackMap.get(entry.target)?.(entry.isIntersecting);
            });
        },
        {
            rootMargin: '400px',
            root: parentRef?.current || null,
        },
    );

    observersMap.set(parentRef?.current || null, intersectionObserver);
    return intersectionObserver;
}

function getIntersectionObserver(parentRef?: React.RefObject<Element>): IntersectionObserver {
    return observersMap.get(parentRef?.current) || initIntersectionObserver(parentRef);
}

export default function useLazyRender(ref: React.RefObject<Element>, parentRef?: React.RefObject<Element>) {
    const [isVisible, toggleVisibility] = React.useState(false);

    React.useEffect(() => {
        if (!ref.current) return;
        const intersectionObserver = getIntersectionObserver(parentRef);
        callbackMap.set(ref.current, toggleVisibility);
        intersectionObserver.observe(ref.current);

        return () => {
            callbackMap.delete(ref.current);
            intersectionObserver.unobserve(ref.current);
        };
    }, []);

    return isVisible;
}
