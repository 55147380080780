import * as React from 'react';
import styles from './Item.scss';
import { ICategories, IInventoryList, IInventoryState, Item } from '~/interfaces';
import classNames from 'classnames';
import { t } from '~/helpers/localization';
import { INVENTORY_KEY_BY_CATEGORY } from '~/helpers/consts';
import { shallowEqual, useSelector } from 'react-redux';
import { State } from '~/Reducers';
import { isAuthorizedUser } from '~/config/user';
import { CREWS_CATEGORY, DOLLS_CATEGORY, ENSIGNS_CATEGORY, PERMOFLAGES_CATEGORY, VEHICLE_CATEGORY } from '~/Actions/ActionCategory';
import WoWSEntity from '@wg/wows-entities/wrappers/react/WoWSEntity';
import { ItemTypes, PresentationStyles } from '@wg/wows-entities/const';
import Message from '@wg/wows-react-uikit/Message';
import ReactDOM from 'react-dom';
import { isInGameBrowser } from '~/helpers/utils';
import { playButtonClickSound, playCrewRandomVOSample } from '~/helpers/audioApi';
import { openPort } from '~/helpers/port';
import LazyElement from '~/Components/LazyElement/LazyElement';

interface IItem {
    category: ICategories;
    item: Item;
    onRender?: () => void;
    parentRef?: React.RefObject<Element>;
}

const isItemOwned = (item: Item, category: ICategories, inventory: IInventoryState): boolean => {
    if (!isAuthorizedUser()) {
        return false;
    }

    const inventoryKey = INVENTORY_KEY_BY_CATEGORY[category] as IInventoryList;
    const items = (inventory[inventoryKey] as string[]) || [];
    return items.includes(item.id);
};

interface IState {
    inventory: IInventoryState;
}

const stateSelector = (state: State): IState => {
    return {
        inventory: state.ReducerApp.inventory,
    };
};

const ItemComp = (props: IItem) => {
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);
    const isOwned = isItemOwned(props.item, props.category, appState.inventory);

    let weType: string;
    if (props.category === VEHICLE_CATEGORY) weType = ItemTypes.VEHICLES;
    if (props.category === CREWS_CATEGORY) weType = ItemTypes.CREWS;
    if (props.category === PERMOFLAGES_CATEGORY) weType = ItemTypes.PERMOFLAGES;
    if (props.category === ENSIGNS_CATEGORY) weType = ItemTypes.ENSIGNS;
    if (props.category === DOLLS_CATEGORY) weType = props.item.type as string;

    const isVehiclePreviewEnabled = isInGameBrowser && ([ItemTypes.VEHICLES, ItemTypes.PERMOFLAGES] as string[]).includes(weType);
    // @ts-ignore
    const isVoicePreviewEnabled = isInGameBrowser && weType === ItemTypes.CREWS && props.item.hasSampleVo;

    const isPreviewEnabled = isVehiclePreviewEnabled || isVoicePreviewEnabled;

    // @ts-ignore
    const sampleVehicleId = props.item.vehicles?.[0]?.vehicle?.id;

    function previewVoice() {
        if (!isVoicePreviewEnabled) return;
        const crewId = +props.item.id;
        if (!crewId) return;
        playCrewRandomVOSample(crewId);
    }

    function previewVehicle() {
        if (!isVehiclePreviewEnabled) return;
        const vehicleId = weType === ItemTypes.VEHICLES ? props.item.id : sampleVehicleId;
        const exteriorId = weType === ItemTypes.PERMOFLAGES ? props.item.id : '';
        openPort(vehicleId, exteriorId);
    }

    function preview(event: React.MouseEvent) {
        if (!isPreviewEnabled) return;
        playButtonClickSound();
        if ((event.target as HTMLElement).closest('.we-js-preview__voice')) {
            return previewVoice();
        }
        return previewVehicle();
    }

    return (
        <LazyElement parentRef={props.parentRef} className={classNames(styles.item, { [styles.notOwned]: !isOwned, [styles.withPreview]: isPreviewEnabled })}>
            <WoWSEntity
                id={props.item.id}
                type={weType}
                presentation={{
                    isOwned: isOwned,
                    style: PresentationStyles.CARD,
                    withTooltip: true,
                    showPreviewElement: isVehiclePreviewEnabled || isVoicePreviewEnabled,
                }}
                customisation={{
                    shipId: sampleVehicleId,
                    hideCrewNoticeInfo: weType === ItemTypes.CREWS,
                    afterHeaderTooltipSlot(node) {
                        ReactDOM.render(
                            <Message type={isOwned ? 'done' : 'error'} style={null}>
                                {isOwned ? t('Получено') : t('Не получено')}
                            </Message>,
                            node,
                        );
                    },
                }}
                onRender={props.onRender}
                onClick={preview}
            />
        </LazyElement>
    );
};

export default ItemComp;
