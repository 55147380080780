import * as React from 'react';
import { FILTERS_NAMES, IFilterValues } from '~/Components/Filter/filtersMap';
import classNames from 'classnames';
import styles from '~/Components/Filter/Filter.scss';
import DivTooltip from '@wg/wows-react-uikit/DivTooltip';
import DefaultTooltip from '~/Components/Tooltip/DefaultTooltip';
import CheckboxWithLabel from '@wg/wows-react-uikit/CheckboxWithLabel';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { updateCategoryFilters } from '~/Actions/ActionFilter';
import { ICategories, IServiceIcons } from '~/interfaces';
import { State } from '~/Reducers';
import { IFilters } from '~/Reducers/ReducerFilter';
import { getFiltersStateByCategory } from '~/settings/filters';
import { CREW_TYPE_FILTER_TITLE, CREW_TYPES, NO_NATION_ITEM } from '~/helpers/consts';

interface IState {
    filters: IFilters;
    facetFilters: IFilters;
}

interface IProps {
    name: FILTERS_NAMES;
    values: IFilterValues[];
    category: ICategories;
}

const stateSelector = (state: State): IState => {
    return {
        filters: state.ReducerFilter.filters,
        facetFilters: state.ReducerFilter.facetFilters,
    };
};

interface IServiceIconsState {
    serviceIcons: IServiceIcons;
}

const serviceIconsSelector = (state: State): IServiceIconsState => {
    return {
        serviceIcons: state.ReducerApp.response.icons,
    };
};

export const ComplexityFilters = ({ name, values, category }: IProps) => {
    const dispatch = useDispatch();
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);
    const filters = getFiltersStateByCategory(appState.filters, category);
    const facetFilters = getFiltersStateByCategory(appState.facetFilters, category);
    const selectedFilters = filters[category][name];

    return (
        <React.Fragment>
            {values.map((complexity) => {
                const isChecked = selectedFilters.includes(complexity.value);
                const isDisabled = facetFilters[category][name].includes(complexity.value);

                const withTooltip = (
                    <DivTooltip className={styles.complexityIconsWrapper} tooltipBody={<DefaultTooltip text={complexity.title.toString()} />} style={{ cursor: 'pointer' }}>
                        {new Array(3).fill(0).map((_, index) => {
                            const iconStyles: React.CSSProperties = {
                                backgroundImage: `url(${index < Number(complexity.value) ? complexity.icons.filled : complexity.icons.empty})`,
                            };
                            return <div key={`complexity_icon_${index}`} className={styles.complexityIconWrapper} style={iconStyles} />;
                        })}
                    </DivTooltip>
                );

                return (
                    <div key={complexity.value} className={styles.filterCheckboxItem}>
                        <CheckboxWithLabel
                            onChange={() => {
                                dispatch(updateCategoryFilters(category, name, complexity.value.toString()));
                            }}
                            isChecked={isChecked}
                            isDisabled={isDisabled}
                            labelText={withTooltip}
                        />
                    </div>
                );
            })}
        </React.Fragment>
    );
};

export const RarityCrewTypeFilter = ({ name, values, category }: IProps) => {
    const dispatch = useDispatch();
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);
    const serviceIconsState = useSelector<State, IServiceIconsState>(serviceIconsSelector, shallowEqual);
    const filters = getFiltersStateByCategory(appState.filters, category);
    const facetFilters = getFiltersStateByCategory(appState.facetFilters, category);
    const selectedFilters = filters[category][name];

    return (
        <React.Fragment>
            {values.map((type) => {
                const isChecked = selectedFilters.includes(type.value);
                const isDisabled = facetFilters[category][name].includes(type.value);

                const filterCrewTypeWrapperStyles = classNames(styles.filterCrewTypeWrapper, {
                    [styles.checkboxDisabled]: isDisabled,
                });

                const FILTER_ICONS_TYPES = {
                    SPECIAL: 'special',
                    RARE: 'rare',
                    UNIQUE: 'unique',
                    LEGENDARY: 'legendary',
                };

                const iconsMap = {
                    [CREW_TYPES.SPECIAL]: FILTER_ICONS_TYPES.SPECIAL,
                    [CREW_TYPES.ADVANCED]: FILTER_ICONS_TYPES.RARE,
                    [CREW_TYPES.ELITE]: FILTER_ICONS_TYPES.UNIQUE,
                    [CREW_TYPES.UNIQUE]: FILTER_ICONS_TYPES.LEGENDARY,
                };

                const rarityIcons = serviceIconsState.serviceIcons?.ribbon;
                const iconType = iconsMap[type.value] as keyof typeof rarityIcons;
                const iconStyles = {
                    backgroundImage: `url(${rarityIcons?.[iconType]})`,
                };

                const content = (
                    <div className={filterCrewTypeWrapperStyles}>
                        <div className={styles.rarityIcon} style={iconStyles}></div>
                        <span className={styles.label}>{CREW_TYPE_FILTER_TITLE[type.value]}</span>
                    </div>
                );

                return (
                    <div key={type.value} className={styles.filterCheckboxItem}>
                        <CheckboxWithLabel
                            onChange={() => {
                                dispatch(updateCategoryFilters(category, name, type.value.toString()));
                            }}
                            isChecked={isChecked}
                            isDisabled={isDisabled}
                            labelText={content}
                        />
                    </div>
                );
            })}
        </React.Fragment>
    );
};

export const NationsFilters = ({ name, values, category }: IProps) => {
    const dispatch = useDispatch();
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);
    const filters = getFiltersStateByCategory(appState.filters, category);
    const facetFilters = getFiltersStateByCategory(appState.facetFilters, category);
    const selectedFilters = filters[category][name];

    const nationsList = values.reduce((nationsList, nation) => {
        const isChecked = selectedFilters.includes(nation.value.toString());
        const isDisabled = facetFilters[category]?.[name].includes(nation.value);

        const className = classNames(styles.filterNation, {
            [styles.checkboxDisabled]: isDisabled,
            [styles.noNation]: nation.value === NO_NATION_ITEM.name,
        });

        const style = {
            ['--backgroundImage']: `url(${nation.icons?.tiny})`,
            cursor: 'pointer',
        };

        const withTooltip = (
            <DivTooltip tooltipBody={<DefaultTooltip text={nation.title.toString()} />}>
                <span className={className} style={style} />
            </DivTooltip>
        );

        nationsList.push(
            <div key={nation.title} className={styles.filterCheckboxItem}>
                <CheckboxWithLabel
                    onChange={() => {
                        dispatch(updateCategoryFilters(category, name, nation.value.toString()));
                    }}
                    isChecked={isChecked}
                    isDisabled={isDisabled}
                    labelText={withTooltip}
                />
            </div>,
        );

        return nationsList;
    }, []);

    const nationsColumns = [];
    for (let i = 0; i < nationsList.length; i += 6) {
        const array = nationsList.slice(i, i + 6);
        nationsColumns.push(
            <div className={styles.columns} key={i}>
                {array}
            </div>,
        );
    }

    return <React.Fragment>{nationsColumns}</React.Fragment>;
};

export const FilterShipTypes = ({ name, values, category }: IProps) => {
    const dispatch = useDispatch();
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);
    const filters = getFiltersStateByCategory(appState.filters, category);
    const facetFilters = getFiltersStateByCategory(appState.facetFilters, category);
    const selectedFilters = filters[category][name];

    return (
        <React.Fragment>
            {values.map((type) => {
                const isChecked = selectedFilters.includes(type.value);
                const isDisabled = facetFilters[category]?.[name].includes(type.value);

                const className = classNames(styles.filterTypeIcon, {
                    [styles.checkboxDisabled]: isDisabled,
                });

                const style = {
                    backgroundImage: `url(${type.icons.default})`,
                    cursor: 'pointer',
                };

                const withTooltip = (
                    <DivTooltip tooltipBody={<DefaultTooltip text={type.title.toString()} />}>
                        <span className={className} style={style} />
                    </DivTooltip>
                );

                return (
                    <div className={styles.filterCheckboxItem} key={type.value}>
                        <CheckboxWithLabel
                            isChecked={isChecked}
                            isDisabled={isDisabled}
                            onChange={() => {
                                dispatch(updateCategoryFilters(category, name, type.value.toString()));
                            }}
                            labelText={withTooltip}
                        />
                    </div>
                );
            })}
        </React.Fragment>
    );
};

export const FilterLevels = ({ name, values, category }: IProps) => {
    const dispatch = useDispatch();
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);
    const filters = getFiltersStateByCategory(appState.filters, category);
    const facetFilters = getFiltersStateByCategory(appState.facetFilters, category);
    const selectedFilters = filters[category][name];

    const levelsItems = values.reduce((state, level) => {
        const isChecked = selectedFilters.includes(level.value);
        const isDisabled = facetFilters[category]?.[name].includes(level.value);
        const className = classNames(styles.label, {
            [styles.checkboxDisabled]: isDisabled,
        });

        state.push(
            <div className={styles.filterCheckboxItem} key={level.value}>
                <CheckboxWithLabel
                    isChecked={isChecked}
                    isDisabled={isDisabled}
                    onChange={() => {
                        dispatch(updateCategoryFilters(category, name, level.value.toString()));
                    }}
                    labelText={<span className={className}>{level.title}</span>}
                />
            </div>,
        );

        return state;
    }, []);

    const columns = [];
    for (let i = 0; i < levelsItems.length; i += 5) {
        const array = levelsItems.slice(i, i + 5);
        columns.push(
            <div className={styles.columns} key={i}>
                {array}
            </div>,
        );
    }

    return <React.Fragment>{columns}</React.Fragment>;
};

export const DefaultFilter = ({ name, values, category }: IProps) => {
    const dispatch = useDispatch();
    const appState = useSelector<State, IState>(stateSelector, shallowEqual);
    const filters = getFiltersStateByCategory(appState.filters, category);
    const facetFilters = getFiltersStateByCategory(appState.facetFilters, category);
    const selectedFilters = filters[category][name];

    return (
        <React.Fragment>
            {values.map((value) => {
                const isChecked = selectedFilters.includes(value.value);
                const isDisabled = facetFilters[category]?.[name].includes(value.value);

                const className = classNames(styles.label, {
                    [styles.checkboxDisabled]: isDisabled,
                });

                return (
                    <div className={styles.filterCheckboxItem} key={value.value}>
                        <CheckboxWithLabel
                            isChecked={isChecked}
                            isDisabled={isDisabled}
                            onChange={() => {
                                dispatch(updateCategoryFilters(category, name, value.value.toString()));
                            }}
                            labelText={<span className={className}>{value.title}</span>}
                        />
                    </div>
                );
            })}
        </React.Fragment>
    );
};
