import { tpl } from '~/helpers/tpl';

export const query = tpl`
    {
        inventory {
            vehicles
            crew {
                id
            }
            hold {
                id
                vehicles {
                    vehicleId
                    count
               }
            }
            storage {
                id
            }
            dogTag
            activeDogTag {
                isColorizable
                background {
                    id
                    symbolOffsetY
                    icons {
                        medium
                    }
                }
                symbol {
                    id
                    icons {
                        medium
                    }
                }
                backgroundColor
                borderColor
                texture {
                    backgroundIcons {
                        medium
                    }
                    borderIcons {
                        medium
                    }
                }
                showClanTag
                clanTag {
                    x
                    y
                    fontColor
                }
            }
        }
    }
`;
