import * as React from 'react';
import { IFilters } from '~/Reducers/ReducerFilter';
import { ICategories } from '~/interfaces';
import styles from '~/Components/Filter/Filter.scss';
import { getFiltersStateByCategory } from '~/settings/filters';
import { getDiapasonOfNumbers } from '~/helpers/array';
import { getLevelFromRomanFormat } from '~/helpers/levels';
import RenderFilterByType from '~/Components/Filter/RenderFilterByType';
import { FILTERS_NAMES, LEVEL_SHIP_NAME_FILTER } from '~/Components/Filter/filtersMap';

interface IRenderActiveFilters {
    filters: IFilters;
    category: ICategories;
}

interface IRenderActiveLevels {
    levels: string[];
}

const RenderActiveLevels = (props: IRenderActiveLevels) => {
    const levels = props.levels.map((level) => Number(level));
    const levelsDiapasons = getDiapasonOfNumbers(levels);

    return (
        <React.Fragment>
            {levelsDiapasons.map((diapason) => {
                let content = null;
                const diapasonData = diapason.split ? diapason.split('-') : [];
                if (diapasonData.length > 1) {
                    content = `${getLevelFromRomanFormat(+diapasonData[0])}-${getLevelFromRomanFormat(+diapasonData.pop())}`;
                } else {
                    content = getLevelFromRomanFormat(+diapason);
                }

                return (
                    <div className={styles.activeItemFilter} key={diapason}>
                        {content}
                    </div>
                );
            })}
        </React.Fragment>
    );
};

const RenderActiveFilters = (props: IRenderActiveFilters) => {
    const filters = getFiltersStateByCategory(props.filters[props.category], props.category);
    const keys = Object.keys(filters);

    return (
        <React.Fragment>
            {keys.map((filterName: FILTERS_NAMES) => {
                switch (filterName) {
                    case LEVEL_SHIP_NAME_FILTER:
                        const levels = filters[filterName] as string[];
                        return <RenderActiveLevels levels={levels} key={filterName} />;

                    default:
                        return (
                            <React.Fragment key={filterName}>
                                {filters[filterName].map((value: string) => {
                                    return <RenderFilterByType key={`${filterName}_${value}`} value={value} filterName={filterName} />;
                                })}
                            </React.Fragment>
                        );
                }
            })}
        </React.Fragment>
    );
};

export default RenderActiveFilters;
