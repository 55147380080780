import { CREWS_CATEGORY_TYPES, CREWS_TALENTS_TYPES, NO_NATION_ITEM } from '~/helpers/consts';
import { Crew } from '~/vortex';
import { _Item } from '~/interfaces';
import ResponseItemMap from './index';
import { CREWS_CATEGORY } from '~/Actions/ActionCategory';

interface IItemCrew {
    talents: string[];
}

type IItemCrewType = Crew & _Item & IItemCrew;

export const prepareCrews = (responseCrews: Crew[]): Crew[] => {
    const rareCrews: Crew[] = [];
    const crews = responseCrews
        .map((item: IItemCrewType) => {
            if (ResponseItemMap.isLegendaryItem(CREWS_CATEGORY, item)) {
                item.isRareItem = true;
                rareCrews.push(item);
            } else if (ResponseItemMap.isUniqueItem(CREWS_CATEGORY, item)) {
                item.isUniqueItem = true;
            }

            if (item.tags.includes(CREWS_CATEGORY_TYPES.HISTORY)) {
                item.category = CREWS_CATEGORY_TYPES.HISTORY;
            } else if (item.tags.includes(CREWS_CATEGORY_TYPES.EVENT)) {
                item.category = CREWS_CATEGORY_TYPES.EVENT;
            } else if (item.tags.includes(CREWS_CATEGORY_TYPES.SPECIAL)) {
                item.category = CREWS_CATEGORY_TYPES.SPECIAL;
            } else {
                item.category = CREWS_CATEGORY_TYPES.SPECIAL;
            }

            item.talents = [];

            if (item.tags.includes(CREWS_TALENTS_TYPES.UPPERKS)) {
                item.talents.push(CREWS_TALENTS_TYPES.UPPERKS);
            }

            if (item.tags.includes(CREWS_TALENTS_TYPES.TALANTS)) {
                item.talents.push(CREWS_TALENTS_TYPES.TALANTS);
            }

            if (!item.tags.includes(CREWS_TALENTS_TYPES.UPPERKS) && !item.tags.includes(CREWS_TALENTS_TYPES.TALANTS)) {
                item.talents.push(CREWS_TALENTS_TYPES.DEFAULT);
            }

            if (!item.nation) {
                item.nation = NO_NATION_ITEM;
            }

            return item;
        })
        .filter((item) => {
            if (ResponseItemMap.getExcludedCrewIds().includes(item.id)) {
                return false;
            }

            return !item.isRareItem;
        });

    return [...rareCrews, ...crews];
};
