import { Dispatch } from 'redux';
import { loadAccountClanData, loadDataFromGlossary, loadDataFromInventory } from '~/helpers/ajax';
import { query as featuresTagsQuery } from '../Queries/featuresTags';
import { query as complexitiesQuery } from '../Queries/complexities';
import { query as infoQuery } from '../Queries/Info';
import { query as vehiclesQuery } from '../Queries/vehicles';
import { query as crewsQuery } from '../Queries/crews';
import { query as permoflagesQuery } from '../Queries/permoflages';
import { query as ensignsQuery } from '../Queries/ensigns';
import { query as dollsQuery } from '../Queries/dolls';
import { query as inventoryQuery } from '../Queries/Inventory';
import { query as serviceIcons } from '../Queries/serviceIcons';
import { getUserId, isAuthorizedUser } from '~/config/user';
import { IAppInit } from '~/Actions/ActionAppTypes';
import { IInventoryData, IResponseData } from '~/interfaces';

export const ACTION_TYPE_FINISH_LOADED_INFO = 'ACTION_TYPE_FINISH_LOADED_INFO';
export const ACTION_TYPE_DIALOG_LOADER = 'ACTION_TYPE_DIALOG_LOADER';
export const ACTION_TYPE_LOADED_INFO_ERROR = 'ACTION_TYPE_LOADED_INFO_ERROR';
export const ACTION_TYPE_CHANGE_SLIDE = 'ACTION_TYPE_CHANGE_SLIDE';
export const ACTION_TYPE_START_LOAD_DATA = 'ACTION_TYPE_START_LOAD_DATA';
export const ACTION_TYPE_FINISH_LOAD_DATA = 'ACTION_TYPE_FINISH_LOAD_DATA';
export const ACTION_TYPE_SET_DEV_MODE = 'ACTION_TYPE_SET_DEV_MODE';

export const App_INIT = 'App_INIT';

export type SERVICE_ICONS_RARITY = {
    rare: string;
    special: string;
    standard: string;
    unique: string;
    legendary?: string;
};

export type ServiceIcons = {
    ribbon: SERVICE_ICONS_RARITY;
};

export type RawServiceIcons = {
    ribbon: {
        small: SERVICE_ICONS_RARITY;
    };
};

export const AppInit = () => (dispatch: Dispatch<IAppInit>) => {
    dispatch({
        type: App_INIT,
    });
};

export const loadDataByQuery = (query: string, category: string) => {
    return (dispatch: Dispatch) => {
        dispatch({
            type: ACTION_TYPE_START_LOAD_DATA,
        });

        loadDataFromGlossary({
            query: infoQuery,
        })
            .then((response) => {
                dispatch({
                    type: ACTION_TYPE_FINISH_LOAD_DATA,
                    category,
                    response,
                });
            })
            .catch((response) => {
                dispatch({
                    type: ACTION_TYPE_LOADED_INFO_ERROR,
                });
            });
    };
};

export const loadAppData = () => {
    return (dispatch: Dispatch) => {
        const requests = [
            loadDataFromGlossary({
                query: infoQuery,
            }),
            loadDataFromGlossary({
                query: vehiclesQuery,
            }),
            loadDataFromGlossary({
                query: crewsQuery,
            }),
            loadDataFromGlossary({
                query: permoflagesQuery,
            }),
            loadDataFromGlossary({
                query: ensignsQuery,
            }),
            loadDataFromGlossary({
                query: dollsQuery,
            }),
            loadDataFromGlossary({
                query: featuresTagsQuery,
            }),
            loadDataFromGlossary({
                query: complexitiesQuery,
            }),
            loadDataFromGlossary({
                query: serviceIcons,
            }),
        ];

        if (isAuthorizedUser()) {
            requests.push(
                loadDataFromInventory({
                    query: inventoryQuery,
                }),
                loadAccountClanData(getUserId()),
            );
        }

        const promises = Promise.all(requests);

        promises
            .then((response: IResponseData[]) => {
                let clanData;
                if (isAuthorizedUser()) {
                    clanData = response.pop()?.data;
                }

                let data = response.reduce((info, item) => {
                    return { ...info, ...item.data };
                }, {});
                let inventory = null;
                if (isAuthorizedUser()) {
                    const inventoryData = data as IInventoryData;
                    inventory = inventoryData.inventory;
                }

                // @ts-ignore
                const rawIcons = data.icons as RawServiceIcons;
                const icons = Object.entries(rawIcons).reduce((result, [key, value]) => {
                    return {
                        ...result,
                        [key]: value.small,
                    };
                }, {} as ServiceIcons);
                data = { ...data, icons: icons };

                dispatch({
                    type: ACTION_TYPE_FINISH_LOADED_INFO,
                    response: data,
                    inventory,
                    clanData,
                });
            })
            .catch((response) => {
                dispatch({
                    type: ACTION_TYPE_LOADED_INFO_ERROR,
                });
            });
    };
};

export const setDevModeIfNeeded = () => {
    return {
        type: ACTION_TYPE_SET_DEV_MODE,
        isDevMode: !!new URLSearchParams(document.location.search).get('devmode'),
    };
};

export const dialogLoader = (isVisible: boolean) => {
    return {
        type: ACTION_TYPE_DIALOG_LOADER,
        isVisible,
    };
};

export const changePromoSlide = (slide: number, isFinish: boolean) => {
    return {
        type: ACTION_TYPE_CHANGE_SLIDE,
        slide,
        isFinish,
    };
};
