import { tpl } from '~/helpers/tpl';

export const query = tpl`
{
    featuresTags(lang: "{{ languageCode }}") {
        id
        complexity {
            level
            header
            description
            icons {
                filled
                empty
                localFilled
                localEmpty
            }
        }
        featuresTags {
            color
            type
                mark
            icons {
                large
                small
                localLarge
                localSmall
            }
        }
        featuresTagsPerCategory {
            category {
                name
                mark
                icons {
                default
                    localDefault
                }
            }
            featuresTags {
                color
                type
                    mark
                icons {
                    large
                    small
                    localLarge
                    localSmall
                }
            }
        }
    }
}
`;
