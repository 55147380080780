import { t } from './localization';
import {
    CREWS_CATEGORY,
    DOLLS_CATEGORY,
    ENSIGNS_CATEGORY,
    INVENTORY_CREW_KEY,
    INVENTORY_DOGTAG_KEY,
    INVENTORY_HOLD_KEY,
    INVENTORY_STORAGE_KEY,
    INVENTORY_VEHICLES_KEY,
    PERMOFLAGES_CATEGORY,
    PROFILE_PAGE,
    VEHICLE_CATEGORY,
} from '~/Actions/ActionCategory';
import { Item } from '~/interfaces';

export const ENSIGNS_CATEGORY_TYPES = {
    REW: 'reward',
    GROUP_REWARD: 'group_reward',
    HIS: 'historical',
    SPE: 'special',
};

export const CREWS_CATEGORY_TYPES = {
    HISTORY: 'history',
    EVENT: 'ingameevent',
    SPECIAL: 'anime',
};

export const TYPE_ALL = '0';
export const TYPE_IS_HAVE = '1';
export const TYPE_IS_NOT_HAVE = '2';

export const TAGS = {
    CREWS_ANIME: 'anime',
    HIDDEN: 'hidden',
    PRESERVED: 'preserved',
    ARPEGGIO: 'arpeggio',
    CH_DRAGONS: 'ch_dragons',
    HSF: 'highSchoolFleet',
    AZURLANE: 'azurlane',
    DEMO_WITHOUT_STATS: 'demoWithoutStats',
};

export const CREWS_TALENTS_TYPES = {
    DEFAULT: 'default',
    UPPERKS: 'upperks',
    TALANTS: 'talants',
    UNIQUE: 'unique',
};

export const DOLLS_TYPES = {
    PATCH: 'patch',
    SUBSTRATE: 'substrate',
    BACKGROUND: 'background',
    EMBLEM: 'emblem',
};

export const PERMOFLAGES_TYPES = {
    SKIN: 'mskin',
    PERMOFLAGE: 'permoflage',
};

export const CREW_TYPES = {
    ELITE: 'elite',
    UNIQUE: 'unique',
    ADVANCED: 'advanced',
    SPECIAL: 'special',
    COMMON: 'common',
};

export const FILTER_TITLE = {
    [ENSIGNS_CATEGORY_TYPES.REW]: t('Наградные'),
    [ENSIGNS_CATEGORY_TYPES.HIS]: t('Памятные'),
    [ENSIGNS_CATEGORY_TYPES.SPE]: t('Особые'),
    [CREWS_CATEGORY_TYPES.HISTORY]: t('Исторические'),
    [CREWS_CATEGORY_TYPES.EVENT]: t('События'),
    [CREWS_CATEGORY_TYPES.SPECIAL]: t('Другие'),
    [CREWS_TALENTS_TYPES.DEFAULT]: t('Обычные'),
    [CREWS_TALENTS_TYPES.TALANTS]: t('Национальные таланты'),
};

export const CREW_TYPE_FILTER_TITLE = {
    [CREW_TYPES.UNIQUE]: t('Легендарные'),
    [CREW_TYPES.ADVANCED]: t('Редкие'),
    [CREW_TYPES.ELITE]: t('Элитные'),
    [CREW_TYPES.SPECIAL]: t('Особые'),
};

export const TAG_CATALOGUE_HIDDEN_IF_MISSING = 'catalogueHiddenIfMissing';
export const TAG_CATALOGUE_LEGENDARY = 'catalogueLegendary';

export const navigate = {
    [PROFILE_PAGE]: '/profile',
    [VEHICLE_CATEGORY]: '/vehicles',
    [CREWS_CATEGORY]: '/crews',
    [ENSIGNS_CATEGORY]: '/ensigns',
    [PERMOFLAGES_CATEGORY]: '/permoflages',
    [DOLLS_CATEGORY]: '/dogtags',
    PORT: '/port/:shipId', // with exteriorId as query parameter
};

export const isRareItem = (item: Item) => item.tags?.includes(TAG_CATALOGUE_LEGENDARY);
export const isUniqueItem = (item: Item) => !isRareItem(item) && item.tags?.includes(TAG_CATALOGUE_HIDDEN_IF_MISSING);

export const promoWidgetTypes = {
    welcome: 'welcome',
};

export const KEY_CODE = {
    ESC: 27,
};

export const INVENTORY_KEY_BY_CATEGORY = {
    [VEHICLE_CATEGORY]: INVENTORY_VEHICLES_KEY,
    [CREWS_CATEGORY]: INVENTORY_CREW_KEY,
    [PERMOFLAGES_CATEGORY]: INVENTORY_HOLD_KEY,
    [ENSIGNS_CATEGORY]: INVENTORY_STORAGE_KEY,
    [DOLLS_CATEGORY]: INVENTORY_DOGTAG_KEY,
};

export const ENSIGN_TYPES = {
    [ENSIGNS_CATEGORY_TYPES.REW]: t('Наградной флаг'),
    [ENSIGNS_CATEGORY_TYPES.HIS]: t('Памятный флаг'),
    [ENSIGNS_CATEGORY_TYPES.SPE]: t('Особый флаг'),
};

export const DEFAULT_DOG_TAG_COMPONENTS = {
    TEXTURE_ID: '4290137008',
    BACKGROUND_COLOR_ID: '4293577648', // black bg
    BORDER_COLOR_ID: '4283911088', // yellow border
};

export const SUPERSHIP_ICON = '★';

export const NO_NATION_ITEM = {
    title: t('Без нации'),
    name: 'no_nation',
};

export const WITHOUT_NATION_TAG = 'withoutNation';
