import { createBrowserHistory } from 'history';
import { useLocation } from 'react-router-dom';
import React from 'react';

export const history = createBrowserHistory();
export const pushToHistory = (url: string) => history.push(url);

export const goBack = () => history.goBack();

export function useQuery(): URLSearchParams {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

// @ts-ignore
window.onClientStateChange = (data: string) => {
    const event = JSON.parse(data);
    console.log('CLIENT EVENT:', event);
};
