import React, { useRef } from 'react';
import useLazyRender from '~/hooks/useLazyRender';

interface LazyElementProps {
    className?: string;
    children: React.ReactElement;
    width?: string;
    height?: string;
    parentRef?: React.RefObject<Element>;
}

export default function LazyElement({ width, height, className, children, parentRef }: LazyElementProps) {
    const elementRef = useRef<HTMLDivElement>();
    const isVisible = useLazyRender(elementRef, parentRef);
    return (
        <div ref={elementRef} className={className} style={{ width, height }}>
            {isVisible && children}
        </div>
    );
}
