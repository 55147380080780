import { isAnime } from '~/helpers/vehiclesApi';
import { ACTION_TYPE_FACET_FILTER } from './ActionFilter';
import { Dispatch } from 'redux';
import { State } from '~/Reducers';
import { IFacetFilter } from '~/Actions/ActionFilterTypes';
import { _Item, ICategories, IDialogData, VortexItem } from '~/interfaces';
import { Permoflages, Vehicle } from '~/vortex';
import { IDialogShow, IHideDialog, IResetSearch, ISearch } from '~/Actions/ActionCategoryTypes';

export const ACTION_TYPE_LOAD_SHIPS_DONE = 'ACTION_TYPE_LOAD_SHIPS_DONE';
export const ACTION_TYPE_LOAD_PERMOFLAGES_DONE = 'ACTION_TYPE_LOAD_PERMOFLAGES_DONE';
export const ACTION_TYPE_LOAD_CREWS_DONE = 'ACTION_TYPE_LOAD_CREWS_DONE';
export const ACTION_TYPE_LOAD_ENSIGNS_DONE = 'ACTION_TYPE_LOAD_ENSIGNS_DONE';
export const ACTION_TYPE_LOAD_DOLLS_DONE = 'ACTION_TYPE_LOAD_DOLLS_DONE';
export const ACTION_TYPE_CHANGE_FILTER_CATEGORY = 'ACTION_TYPE_CHANGE_FILTER_CATEGORY';
export const ACTION_UPDATE_PROGRESS_BAR_DEV_MODE = 'ACTION_UPDATE_PROGRESS_BAR_DEV_MODE';

export const ACTION_RESET_SEARCH_VEHICLE = 'ACTION_RESET_SEARCH_VEHICLE';
export const ACTION_SEARCH = 'ACTION_SEARCH';
export const ACTION_RESET_SEARCH = 'ACTION_RESET_SEARCH';
export const ACTION_DIALOG_SHOW = 'ACTION_DIALOG_SHOW';
export const ACTION_HIDE_DIALOG = 'ACTION_HIDE_DIALOG';

export const PROFILE_PAGE = 'common';
export const VEHICLE_CATEGORY = 'vehicles';
export const CREWS_CATEGORY = 'crews';
export const ENSIGNS_CATEGORY = 'ensigns';
export const PERMOFLAGES_CATEGORY = 'permoflages';
export const DOLLS_CATEGORY = 'dogTagComponents';

export const INVENTORY_VEHICLES_KEY = 'vehicles';
export const INVENTORY_CREW_KEY = 'crew';
export const INVENTORY_DOGTAG_KEY = 'dogTag';
export const INVENTORY_HOLD_KEY = 'hold';
export const INVENTORY_STORAGE_KEY = 'storage';

export type ActionAppType = 'Category_INIT';

export const getArrayKeysFromObject = (array: any[], key: string) =>
    array.reduce((state, item) => {
        state.push(item[key]);

        return state;
    }, []);

export const search = (value: string, category: ICategories) => {
    const searchText = value.toLowerCase().trim();

    return (dispatch: Dispatch<ISearch>, getState: () => State) => {
        if (!searchText) {
            dispatch({
                type: ACTION_SEARCH,
                result: null,
            });

            return;
        }

        const state = getState();
        const globalFilters = state.ReducerFilter.globalFilters;
        const items: VortexItem[] = state.ReducerApp.response[category];
        const results = items.filter((item) => {
            const title = (item.title || '').toLowerCase().replace(/\u00A0/gi, ' ');

            const animeItem = item as _Item;
            if (!globalFilters.author_content && isAnime(animeItem)) {
                return false;
            }

            if (title.includes(searchText)) {
                return true;
            }

            if (category === PERMOFLAGES_CATEGORY) {
                item = item as Permoflages;
                const vehicleTitle = item.vehicles[0]?.vehicle?.title.toLowerCase();
                if (vehicleTitle.includes(searchText)) {
                    return true;
                }
            }

            if (category === VEHICLE_CATEGORY) {
                const ship = item as Vehicle;
                if (searchText.includes(ship.nation.name)) {
                    return true;
                }
            }

            return false;
        });

        dispatch({
            type: ACTION_SEARCH,
            result: results,
        });
    };
};

export const resetSearch = () => (dispatch: Dispatch<IResetSearch>) => {
    dispatch({
        type: ACTION_RESET_SEARCH,
    });
};

export const showDialog = (dialogData: IDialogData) => (dispatch: Dispatch<IDialogShow>) => {
    // TODO: enable after fix WWSD-139431
    // updateBrowserControlState(true);
    dispatch({
        type: ACTION_DIALOG_SHOW,
        dialogData: dialogData,
    });
};

export const hideDialog = () => (dispatch: Dispatch<IHideDialog>) => {
    // TODO: enable after fix WWSD-139431
    // updateBrowserControlState(false);
    dispatch({
        type: ACTION_HIDE_DIALOG,
    });
};

export const resetFacetFilters = (category: string) => (dispatch: Dispatch<IFacetFilter>) => {
    dispatch({
        type: ACTION_TYPE_FACET_FILTER,
        facetFilter: null,
        category,
    });
};
