import * as React from 'react';
import styles from './CategoryLayout.scss';
import { Scrollbars } from 'react-custom-scrollbars';
import css from 'dom-css';
import { ICategories, Item as IItem } from '~/interfaces';
import { onClickByHeaderTitle, parseCategoryTitle } from '~/Layouts/Category/CategoryPreTitle';
import { t } from '~/helpers/localization';
import Item from '~/Components/Item/Item';
import ProgressBar from '~/Components/ProgressBar/ProgressBar';
import classNames from 'classnames';
import Search from '~/Components/Search/Search';
import AdditionalFilter from '~/Components/AdditionalFilter/AdditionalFilter';
import Filter from '~/Components/Filter/Filter';
import { playButtonClickSound, playButtonDialogClickSound } from '~/helpers/audioApi';

interface ICategoryLayout {
    availableAwardsCount: number;
    totalCount: number;
    filteredTotalCount?: number;
    uniqueAwardsCount: number;
    rareAwardsCount: number;
    items: IItem[];
    inventoryItems: any;
    category: ICategories;
}

export const renderThumb = ({ style, ...props }: any) => {
    const thumbStyle = {
        borderRadius: 6,
        backgroundColor: 'rgba(255, 204, 101, .3)',
    };

    return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CategoryLayout = (props: ICategoryLayout) => {
    const categoryBodyRef = React.useRef(null);
    const scrollbarsRef = React.useRef();
    const viewRef = React.useRef();
    const shadowsRef = React.useRef();
    const buttonToTop = React.useRef();

    const handleUpdate = (values: { scrollTop: number }) => {
        if (!shadowsRef.current) {
            return;
        }

        const { scrollTop } = values;
        const shadowTopOpacity = (1 / 20) * Math.min(scrollTop, 20);
        css(shadowsRef.current, { opacity: shadowTopOpacity });

        if (buttonToTop.current) {
            css(buttonToTop.current, {
                opacity: scrollTop > 250 ? 0.4 : 0,
                pointerEvents: scrollTop > 250 ? 'initial' : 'none',
            });
        }
    };

    const handleScrollStart = () => {
        categoryBodyRef.current?.classList.add(styles.cursorDisabled);
    };

    const handleScrollStop = () => {
        categoryBodyRef.current?.classList.remove(styles.cursorDisabled);
    };

    const scrollToTop = () => {
        playButtonClickSound();
        if (scrollbarsRef.current) {
            // @ts-ignore
            scrollbarsRef.current.container.children[0].scrollTo?.({
                behavior: 'smooth',
                top: 0,
            });
        }
    };

    return (
        <React.Fragment>
            <div className={styles.categoryHeader}>
                <div
                    className={styles.categoryHeaderTitle}
                    onClick={(event: React.MouseEvent) => {
                        playButtonDialogClickSound();
                        onClickByHeaderTitle(event, props.category);
                    }}
                    dangerouslySetInnerHTML={{ __html: parseCategoryTitle(props.category) }}
                />
                <ProgressBar
                    category={props.category}
                    availableAwardsCount={props.availableAwardsCount}
                    rareAwardsCount={props.rareAwardsCount}
                    totalCount={props.totalCount}
                    uniqueAwardsCount={props.uniqueAwardsCount}
                />
                <div className={styles.filterWrap}>
                    <Filter category={props.category} availableCount={props.availableAwardsCount} filteredAvailableCount={props.filteredTotalCount} filteredItems={props.items} />
                    <div className={styles.filterBaseLeftPanel}>
                        <Search category={props.category} onKeyDown={null} resetSearch={null} />
                        <AdditionalFilter category={props.category} />
                    </div>
                </div>
                <div ref={shadowsRef} className={classNames(styles.overflowShadows, styles.categoryBodyShadows)} />
            </div>
            <Scrollbars
                autoHide
                className={styles.scrollbarsWrap}
                renderThumbVertical={renderThumb}
                onUpdate={handleUpdate.bind(this)}
                onScrollStart={handleScrollStart.bind(this)}
                onScroll={handleScrollStart.bind(this)}
                onScrollStop={handleScrollStop.bind(this)}
                ref={(ref: any) => {
                    if (!ref) return;
                    scrollbarsRef.current = ref;
                    viewRef.current = ref.view;
                }}
            >
                <div className={styles.categoryBody} ref={categoryBodyRef}>
                    {props.items.length ? (
                        <div className={classNames(styles.categoryBodyContent)}>
                            {props.items.map((item, index) => (
                                <Item parentRef={viewRef} key={`${props.category}_${item.id}_${index}_${props.items.length}`} category={props.category} item={item} />
                            ))}
                        </div>
                    ) : (
                        <div className={styles.filterNotFound}>{t('Ничего не найдено')}</div>
                    )}
                </div>
            </Scrollbars>
            <div className={styles.categoryWrap}>
                <div className={styles.categoryWrapper}>
                    <div className={styles.categoryWrapperContent}>
                        <div className={styles.buttonToTop} ref={buttonToTop} onClick={scrollToTop} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default CategoryLayout;
