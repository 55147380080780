import { tpl } from '~/helpers/tpl';

export const query = tpl`
{
    icons {
        ribbon {
            small {
                unique
                rare
                special
                standard
                legendary
            }
        }
    }
}
`;
