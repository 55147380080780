import { useParams } from 'react-router-dom';
import { goBack, pushToHistory, useQuery } from '~/helpers/router';
import { isInGameBrowser } from '~/helpers/utils';
import { KEY_CODE, navigate } from '~/helpers/consts';
import { PROFILE_PAGE } from '~/Actions/ActionCategory';
import { useEffect } from 'react';
import { openShipPreviewInPort } from '@wg/web2clientapi/port/openShipPreviewInPort';
import { closePortPreview } from '@wg/web2clientapi/port/closePortPreview';
import BackButton from '~/Components/BackButton/BackButton';
import ReactDOM from 'react-dom';

interface PortParams {
    shipId?: string;
}

interface PortQuery {
    exteriorId?: string;
}

function handleEsc(e: KeyboardEvent) {
    if (e.keyCode !== KEY_CODE.ESC) return;
    goBack();
}

export default function Port(): null {
    const { shipId } = useParams<PortParams>();
    const query = useQuery();
    const isAvailable = isInGameBrowser && !!shipId;

    if (!isAvailable) pushToHistory(navigate[PROFILE_PAGE]);

    useEffect(() => {
        if (!isAvailable) return;
        const exteriorId = +query.get('exteriorId') || -1;
        openShipPreviewInPort(+shipId, exteriorId, exteriorId === -1);
        document.body.classList.add('portPage');
        document.addEventListener('keydown', handleEsc);
        return () => {
            closePortPreview();
            document.removeEventListener('keydown', handleEsc);
            document.body.classList.remove('portPage');
        };
    }, []);

    return null;
}
