import { Crew, Doll, Ensign, Nation, Permoflages, Vehicle } from '~/vortex';
import { NAME_BACKGROUND_FILTER, NAME_PATCH_FILTER, NAME_SYMBOL_FILTER } from '~/Actions/ActionFilter';
import { CREWS_CATEGORY, DOLLS_CATEGORY, ENSIGNS_CATEGORY, PERMOFLAGES_CATEGORY, VEHICLE_CATEGORY } from '~/Actions/ActionCategory';
import { categoriesSettings, getArrayFromObjectByKey } from '~/helpers/utils';
import { DOLLS_TYPES, INVENTORY_KEY_BY_CATEGORY, NO_NATION_ITEM, TAGS, TYPE_IS_HAVE, TYPE_IS_NOT_HAVE } from '~/helpers/consts';
import { prepareCrews } from '~/settings/response/crews';
import ResponseItemMap from './response/index';
import { getDefaultDogTagComponents, prepareDolls } from '~/settings/response/dolls';
import { prepareEnsigns } from '~/settings/response/ensigns';
import { _Item, ICategories, IInventory, IInventoryList, IInventoryState, IItemFilters, IResponse, Item, VortexItem } from '~/interfaces';
import {
    COMPLEXITY_SHIP_NAME_FILTER,
    CREW_CATEGORY_NAME_FILTER,
    CREW_TYPE_NAME_FILTER,
    DOLL_CATEGORY_NAME_FILTER,
    ENSIGN_CATEGORY_NAME_FILTER,
    LEVEL_SHIP_NAME_FILTER,
    NATION_NAME_FILTER,
    TYPE_SHIP_NAME_FILTER,
} from '~/Components/Filter/filtersMap';

export const prepareResponseFromFilters = (response: IResponse, inventory: IInventoryState): IResponse => {
    Object.keys(response).forEach((key: ICategories) => {
        if (!INVENTORY_KEY_BY_CATEGORY[key]) {
            return;
        }

        const items = response[key] as VortexItem[];
        const inventoryKey = INVENTORY_KEY_BY_CATEGORY[key] as IInventoryList;
        const inventoryItems = (inventory?.[inventoryKey] || []) as string[];

        items.forEach((item: _Item) => {
            const vortexItemData = item as VortexItem;
            const filters: IItemFilters = {};

            if (key === VEHICLE_CATEGORY) {
                const ship = vortexItemData as Vehicle;
                const featuresTags = response.featuresTags;
                const shipComplexity = featuresTags.find((item) => item.id === ship.id).complexity;

                filters[LEVEL_SHIP_NAME_FILTER] = [ship.level.toString()];
                filters[NATION_NAME_FILTER] = [ship.nation.name];
                filters[TYPE_SHIP_NAME_FILTER] = [ship.type.name];
                filters[COMPLEXITY_SHIP_NAME_FILTER] = [shipComplexity.level.toString()];
            } else if (key === CREWS_CATEGORY) {
                const crew = vortexItemData as Crew;

                filters[NATION_NAME_FILTER] = [crew.nation?.name];
                filters[CREW_CATEGORY_NAME_FILTER] = [crew.category];
                filters[CREW_TYPE_NAME_FILTER] = [crew.type];
            } else if (key === PERMOFLAGES_CATEGORY) {
                const camo = vortexItemData as Permoflages;
                const ship = camo.vehicles[0]?.vehicle;

                filters[LEVEL_SHIP_NAME_FILTER] = [ship?.level.toString()];
                filters[NATION_NAME_FILTER] = [ship?.nation.name];
                filters[TYPE_SHIP_NAME_FILTER] = [ship?.type.name];
            } else if (key === ENSIGNS_CATEGORY) {
                const ensign = vortexItemData as Ensign;

                filters[ENSIGN_CATEGORY_NAME_FILTER] = [ensign.categoryType];
            } else if (key === DOLLS_CATEGORY) {
                const doll = vortexItemData as Doll;
                let type = NAME_BACKGROUND_FILTER;
                if (doll.type === DOLLS_TYPES.PATCH) {
                    type = doll.isPatch ? NAME_PATCH_FILTER : NAME_SYMBOL_FILTER;
                }

                filters[DOLL_CATEGORY_NAME_FILTER] = [type];
            }

            item.filters = {
                display: inventoryItems?.includes(vortexItemData.id) ? [TYPE_IS_HAVE] : [TYPE_IS_NOT_HAVE],
                ...filters,
            };
        });
    });

    return response;
};

export const prepareResponse = (response: IResponse, inventory: IInventory): IResponse => {
    const availableVehicleClass = new Set();
    const availableVehicleNations = new Set();
    const availableVehicleLevels = new Set();
    const rareVehicles: Vehicle[] = [];

    response.vehicles = response.vehicles
        .map((vehicle: Vehicle & _Item) => {
            if (ResponseItemMap.isLegendaryItem(VEHICLE_CATEGORY, vehicle)) {
                vehicle.isRareItem = true;
            } else if (ResponseItemMap.isUniqueItem(VEHICLE_CATEGORY, vehicle)) {
                vehicle.isUniqueItem = true;
            }

            // todo позже появится у них тег аниме
            // todo 11 февраля 16:58 - этот день еще пока не настал, но народ не теряет надежды
            // if (ResponseItemMap.getHardcodeAnimeShipIds().includes(vehicle.id)) {
            //     vehicle.tags.push(TAGS.HSF);
            // }

            availableVehicleClass.add(vehicle.type.name.toLowerCase());
            availableVehicleNations.add(vehicle.nation.name.toLowerCase());
            availableVehicleLevels.add(vehicle.level);

            return vehicle;
        })
        .filter((vehicle) => {
            if (vehicle.isRareItem) {
                rareVehicles.push(vehicle);
            }

            if (ResponseItemMap.getExcludedShipIds().includes(vehicle.id)) {
                return false;
            }

            return !vehicle.isRareItem;
        });

    response.vehicles = [...rareVehicles, ...response.vehicles];
    response.vehicleTypes = response.vehicleTypes.filter((item) => availableVehicleClass.has(item.name.toLowerCase()));
    response.nations = response.nations.filter((item: Nation) => availableVehicleNations.has(item.name.toLowerCase()));

    response.crews = prepareCrews(response.crews);
    response.dogTagComponents = prepareDolls(response.dogTagComponents);
    response.ensigns = prepareEnsigns(response.ensigns);
    response.defaultDogTagComponents = getDefaultDogTagComponents(response.dogTagComponents);

    const inventoryHoldsIds = getArrayFromObjectByKey(inventory.hold || [], 'id');
    const vehiclesHoldsData = (inventory?.hold || []).reduce((state: any, item) => {
        state[item.id] = item;
        return state;
    }, {});

    response.permoflages = response.permoflages
        .reduce((array, item) => {
            item.vehicles = item.vehicles.filter((vehicle) => {
                return !vehicle.vehicle.tags.includes(TAGS.PRESERVED);
            });

            if (item.vehicles.length > 1) {
                const copyItem = { ...item };
                item.vehicles.forEach((i, index) => {
                    const li = { ...copyItem };
                    li.vehicles = li.vehicles.filter((listItem, listIndex) => listIndex === index);

                    if (!inventoryHoldsIds.includes(item.id)) {
                        array.push(li);
                    } else {
                        const availableVehicles = (vehiclesHoldsData[item.id] || {}).vehicles || [];
                        const arrayVehiclesIds = getArrayFromObjectByKey(availableVehicles, 'vehicleId');
                        if (arrayVehiclesIds.includes(i.vehicle?.id.toString())) {
                            array.push(li);
                        }
                    }
                });
            } else {
                array.push(item);
            }

            return array;
        }, [])
        .sort((a, b) => {
            if (a.title < b.title) {
                return -1;
            } else if (a.title > b.title) {
                return 1;
            }

            return 0;
        });

    const rarePermoflages: Permoflages[] = [];
    response.permoflages = response.permoflages
        .map((item: Permoflages & _Item) => {
            const vehicleId = item.vehicles[0]?.vehicle?.id;
            if ((vehicleId && ResponseItemMap.getLegendaryShipIds().includes(vehicleId.toString())) || ResponseItemMap.isLegendaryItem(PERMOFLAGES_CATEGORY, item)) {
                item.isRareItem = true;
                rarePermoflages.push(item);
            } else if (ResponseItemMap.isUniqueItem(PERMOFLAGES_CATEGORY, item)) {
                item.isUniqueItem = true;
            }

            return item;
        })
        .filter((item) => {
            if (ResponseItemMap.getExcludedPermoflagesIds().includes(item.id)) {
                return false;
            }

            if (item.vehicles[0]?.vehicle?.tags.includes(TAGS.DEMO_WITHOUT_STATS)) {
                return false;
            }

            return !item.isRareItem;
        });

    response.permoflages = [...rarePermoflages, ...response.permoflages];

    return response;
};

export const postPrepareResponse = (
    response: IResponse,
    inventory: IInventoryState,
): {
    response: IResponse;
    inventory: IInventoryState;
} => {
    const vehiclesInfo = {
        nations: new Set(),
        types: new Set(),
        levels: new Set(),
    };

    const _isFeatsUniqueItem = (category: ICategories, item: Item) => {
        const inventoryKey = INVENTORY_KEY_BY_CATEGORY[category] as IInventoryList;
        const inventoryItems = (inventory?.[inventoryKey] || []) as string[];

        return !(item.isUniqueItem && !inventoryItems.includes(item.id.toString())) || !item.isUniqueItem;
    };

    const _isFeatsLegendaryItem = (category: ICategories, item: Item) => {
        const inventoryKey = INVENTORY_KEY_BY_CATEGORY[category] as IInventoryList;
        const inventoryItems = (inventory?.[inventoryKey] || []) as string[];

        return !(item.isRareItem && !inventoryItems.includes(item.id.toString())) || !item.isRareItem;
    };

    response.vehicles = response.vehicles.filter((item: Vehicle & _Item) => {
        let isFeats = _isFeatsUniqueItem(VEHICLE_CATEGORY, item);

        if (isFeats) {
            isFeats = _isFeatsLegendaryItem(VEHICLE_CATEGORY, item);
        }

        if (isFeats) {
            vehiclesInfo.nations.add(item.nation.name);
            vehiclesInfo.types.add(item.type.name);
            vehiclesInfo.levels.add(item.level);
        }

        return isFeats;
    });

    // @ts-ignore
    response.vehiclesInfo = vehiclesInfo;

    response.ensigns = response.ensigns.filter((item: Ensign & _Item) => {
        let isFeats = _isFeatsUniqueItem(ENSIGNS_CATEGORY, item);

        if (isFeats) {
            isFeats = _isFeatsLegendaryItem(ENSIGNS_CATEGORY, item);
        }

        return isFeats;
    });

    response.dogTagComponents = response.dogTagComponents.filter((item: Doll & _Item) => {
        let isFeats = _isFeatsUniqueItem(DOLLS_CATEGORY, item);

        if (isFeats) {
            isFeats = _isFeatsLegendaryItem(DOLLS_CATEGORY, item);
        }

        const isCorrectType = [DOLLS_TYPES.PATCH, DOLLS_TYPES.SUBSTRATE, DOLLS_TYPES.BACKGROUND].includes(item.type);

        return isFeats && isCorrectType;
    });

    const permoflagesVehicleInfo = {
        nations: new Set(),
        types: new Set(),
        levels: new Set(),
    };

    const includeNativeForced = categoriesSettings.permoflages?.includeNativeForced || [];
    response.permoflages = response.permoflages.filter((item: Permoflages & _Item) => {
        const itemId = item.id.toString();
        let isFeats = true;
        if (!item.vehicles.length) {
            isFeats = false;
        } else if (ResponseItemMap.getExcludedPermoflagesIds().includes(itemId)) {
            isFeats = false;
        } else if (item.isUniqueItem && !inventory?.hold?.includes(itemId)) {
            isFeats = false;
        } else if (item.isRareItem && !inventory?.hold?.includes(itemId)) {
            isFeats = false;
        } else if (item.vehicles[0] && item.vehicles[0].isNative && !includeNativeForced.includes(itemId)) {
            isFeats = false;
        } else if (!inventory) {
            isFeats = !item.isRareItem || !item.isUniqueItem;
        }

        const vehicle = item.vehicles[0]?.vehicle || {};

        if (isFeats) {
            permoflagesVehicleInfo.nations.add(vehicle.nation.name);
            permoflagesVehicleInfo.types.add(vehicle.type.name);
            permoflagesVehicleInfo.levels.add(vehicle.level);
        }

        return isFeats;
    });

    // @ts-ignore
    response.permoflagesVehiclesData = permoflagesVehicleInfo;

    response.crews = response.crews.filter((crew: Crew & _Item) => {
        let isFeats = _isFeatsUniqueItem(CREWS_CATEGORY, crew);
        if (isFeats) {
            isFeats = _isFeatsLegendaryItem(CREWS_CATEGORY, crew);
        }

        return isFeats;
    });

    // hardcode crew without nation to crews and hardcode "no_nation" to nations
    response.nations = [].concat(NO_NATION_ITEM, response.nations);

    const crewsNations = response.crews.reduce((Set, item) => {
        return !item.nation ? Set.add(NO_NATION_ITEM.name) : Set.add(item.nation?.name) && Set;
    }, new Set());
    // @ts-ignore
    response.crewsNations = response.nations.filter((item) => crewsNations.has(item.name));

    const crewsInfo = {
        nations: crewsNations,
    };

    // @ts-ignore
    response.crewsInfo = crewsInfo;

    return {
        response,
        inventory,
    };
};
