import { tpl } from '~/helpers/tpl';

export const query = tpl`
{
    complexities(lang: "{{ languageCode }}") {
        level
        header
        description
        icons {
            filled
            empty
            localFilled
            localEmpty
        }
    }
}
`;
